<template>
  <div class="template meeting grey lighten-3" >
    <v-app-bar dense app color="black" class="component" dark fixed >
      <template v-if="$isEmpty(meeting)">
        <v-toolbar-title class="white--text">
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          <span>Loading...</span>

        </v-toolbar-title>
      </template>
 
      <template v-else>
        <v-toolbar-title class="white--text text--truncate text-shadow">
          <!-- <v-icon class="mr-2">mdi-chevron-left</v-icon> -->
          <span>{{meeting.client.firstName}} + {{meeting.advisor.firstName}}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="secondary" :icon="$vuetify.breakpoint.smAndDown" @click="statsOn = true">
          <span v-if="$vuetify.breakpoint.mdAndUp" class="mr-2">Profile</span>
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </template>



  </v-app-bar>


    <div class=" px-0" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height fluid class="align-start align-items-start d-block col-12 col-md-6 pa-0">
        <template v-if="!$isEmpty(meeting) &&  correctDevice">
          <room v-if="!endSession "
            :micOn="micOn"
            :cameraOn="cameraOn"
            :switchCamera="cameraOption"

            :meeting="meeting"
            :statsOn="statsOn"
            :endSession="endSession"></room>
        </template>


        <template v-else>

          <v-skeleton-loader
            class="d-block col-12 col-md-6 mx-md-auto"
          type="image, divider, image, divider, actions"
        ></v-skeleton-loader>
        </template>

      </v-container>
  </div>


<div class="actions px-4" v-if="!$isEmpty(meeting)">
  <div class="col-12 mx-auto d-flex align-center justify-space-between pa-0 pb-2">
    <div class="col-3 pa-0 align-center">
      <v-btn fab large dark class="chat-end" color="error" @click="endCall()"><v-icon>mdi-phone-hangup</v-icon></v-btn>
    </div>
    <div class="col-auto pa-0 align-center">
      <v-btn fab large :dark="!cameraOn" class="chat-end" :color="cameraOn ? 'white' : 'grey'" @click="toggleCamera">
        <v-icon v-if="cameraOn">mdi-camera-outline</v-icon>
        <v-icon v-else>mdi-camera-off</v-icon>
      </v-btn>
      <v-btn fab large :dark="!micOn" class="chat-end ml-4" :color="micOn ? 'white' : 'grey'" @click="toggleMic">
        <v-icon v-if="micOn">mdi-microphone-outline</v-icon>
        <v-icon v-else>mdi-microphone-off</v-icon>
      </v-btn>
      <v-btn fab large class="chat-end ml-4" color="white" @click="switchCamera">
        <v-icon>mdi-camera-flip-outline</v-icon>
      </v-btn>
    </div>
  </div>
</div>

<div ref="propbottom">

</div>

<!-- <v-dialog
  v-model="dialogDecline"
  persistent
  max-width="320"
>
  <v-card>
    <v-card-title class="headline">
      Decline this meeting?
    </v-card-title>
    <v-card-text>Care to share a reason?</v-card-text>
    <v-textarea
    class="mx-2"
    solo flat background-color="grey lighten-3"
    auto-grow
    placeholder="It's best to be polite :)"
    rows="1"
    row-height="20"
    v-model.trim="decline.reason"
    ></v-textarea>
    <v-card-actions v-if="!decline.loading">
      <v-btn
        color="green darken-1"
        text
        @click="dialogDecline = false"
      >
        Keep Meeting
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-1"
        text
        @click="declineMeeting"
      >
        Decline
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-card-actions>
  </v-card>
</v-dialog> -->




  </div>
</template>

<script>
import {db} from '@/firebase';
import { isMobile, isIOS, isChrome, isSafari, isFirefox, osName } from 'mobile-device-detect';
const room = () => import("@/views/Room.vue");
export default {
  name: 'meeting',
  components:{room},
  computed: {
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
    meetings: function (){ return this.$store.state.meetings},
    correctDevice: function(){
      process.env.NODE_ENV === "development" ? console.log( 'isMobile', isMobile ) : null;
      process.env.NODE_ENV === "development" ? console.log( 'osName', osName ) : null;
      // if mobile android
      if(osName === 'Android'){
        //supported browsers
        if(isChrome || isFirefox){return true;}
        else{return false;}
      }
      //if mobile IOS
      else if(isIOS){
        if(isSafari){return true;}
        else{return false;}
      }
      else if (!isMobile){
        if(isChrome || isFirefox){return true;}
        else{return false;}
      }
      else{
        return false;
      }
    }
  },
  watch:{
    meeting: function(val){

    },
    meetingId:{
      handler(val){
        process.env.NODE_ENV === "development" ? console.log( 'watch meeting', val) : null;
        this.$bind('meeting', db.collection('meetings').doc(val))
      },
    }
  },
  data: () => ({
    meeting: [],
    dialogDecline: false,
    meetingId: null,
    messages:[],
    showTimes:false,
    timeEdited: false,
    decline:{
      reason:null,
      loading:false
    },
    micOn: true,
    cameraOn: true,
    cameraOption: 'user',
    meetingLoading: false,
    newDates: [],
    statsOn: false,
    endSession: false,
  }),
  methods:{
    // getProposal(){
    //   this.$store.dispatch('getProposal',this.meetingId);
    // },
    goBack(){
      this.$router.push('/my/calendar')
    },
    toggleMic(){
      this.micOn = !this.micOn;
    },
    toggleCamera(){
      this.cameraOn = !this.cameraOn;
    },
    getCameraList(data){
      process.env.NODE_ENV === "development" ? console.log( 'data',data ) : null;
      // process.env.NODE_ENV === "development" ? console.log( 'camera List', data ) : null;
      let isMultipleCameras = data.filter(track => track.kind === 'videoinput');
      // process.env.NODE_ENV === "development" ? console.log( 'isMultipleCameras', isMultipleCameras ) : null;
      alert('isMultipleCameras', isMultipleCameras.length > 1)
    },
    endCall(){
      this.endSession = true;
      this.goBack();
    },
    switchCamera(){
      this.cameraOption = this.cameraOption == 'user' ? 'environment' : 'user'
    },
  },
  mounted(){
    this.meetingId = this.$route.params.meetingId;
  },
  beforeDestroy(){
    // this.$unbind('meeting').catch(e=>{process.env.NODE_ENV === "development" ? console.log( 'error', e ) : null;})
  }
};
</script>


<style lang="scss">
.meeting.template{
  position: relative;
  .component.header{
    background: rgba(0,0,0,.5);
  }
  .page{
      padding-top: 64px;
      padding-bottom: 74px;
  }
  .subtitle-1{
    line-height: 18px;
  }
  hr{
    border-style: dashed;
    opacity:.3;
  }
  .actions{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 72px;
    .chat-end{

    }
  }
}
.mobile .meeting.page{

}
.mobile .meeting.component{
  .v-toolbar__content{
    padding:4px 2px;
  }
}
</style>
